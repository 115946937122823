import HexController from 'controllers/hex_controller'

export default class DesignGuidanceModalController extends HexController {
  static values = {
    filePresent: Boolean,
    testingGoalPresent: Boolean
  };

  filesListChanged(present) {
    this.filePresentValue = !!present;
    this.designGuidanceChanged();
  }

  testingGoalChanged(present) {
    this.testingGoalPresentValue = !!present;
    this.designGuidanceChanged();
  }

  designGuidanceChanged() {
    const canShow = this.filePresentValue && this.testingGoalPresentValue;
    document.querySelector('.design-guidance-badge').classList.toggle('show-badge', !canShow);
    document.dispatchEvent(new CustomEvent('design-guidance-changed', {
      detail: {
        canShow: canShow
      }
    }));
  }

  close() {
    Modalbox.hide();
  }
}
