// switch_controller.js
import HexController from "controllers/hex_controller"
import { el } from 'modules/DOM/element';

export default class extends HexController {
  static targets = ["checkbox"]

  connect() {
    // Hide the original checkbox
    this.checkboxTarget.style.display = 'none';
    // Create and insert the switch
    this.appendSwitch();
    // Update the switch state when the checkbox changes, used when the checkbox label is clicked
    this.checkboxTarget.addEventListener('change', () => {
      this.updateSwitchState()
    });
    this.updateSwitchState();
  }

  appendSwitch() {
    this.checkboxTarget.insertAdjacentElement('afterend', el('div', {
      // Class names
      classList: ['hex-switch-container'],
      children: [
        el('div', {
          // Class names
          classList: ['hex-switch', this.checkboxTarget.checked ? 'hex-switch--active' : ''],
          // Generic attributes
          attributes: {
            role: 'switch',
            'aria-checked': this.checkboxTarget.checked,
            tabindex: 0,
          },
          // Insert the background and the point elements
          children: [
            el('span', { classList: ['hex-switch__bg'] }),
            el('span', { classList: ['hex-switch__point'] }),
          ],
          // Event listeners
          events: {
            click: () => this.toggle(),
            keydown: (e) => {
              if (e.key === ' ' || e.key === 'Enter') {
                e.preventDefault();
                this.toggle();
              }
            },
          },
        })
      ],
    }));
  }

  toggle() {
    this.checkboxTarget.checked = !this.checkboxTarget.checked;
    // Trigger change event on the checkbox
    this.checkboxTarget.dispatchEvent(new Event('change', { bubbles: true }));
    this.updateSwitchState();
  }

  updateSwitchState() {
    const switchEl = this.element.querySelector('.hex-switch');
    if (this.checkboxTarget.checked) {
      switchEl.classList.add('hex-switch--active');
      switchEl.setAttribute('aria-checked', 'true');
    } else {
      switchEl.classList.remove('hex-switch--active');
      switchEl.setAttribute('aria-checked', 'false');
    }
  }
}