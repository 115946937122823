import HexController from 'controllers/hex_controller'

export default class DesignGuidanceModalController extends HexController {
  static values = {
    url: String
  }

  connect() {
    this.element.addEventListener('click', (e) => this.openModal(e));
    // NB: The openModal method will work only in case at least one of these controllers is present in the page,
    // since it's used in the navbar and in the navigation sidebar this should always be the case.
    // This is needed since the static method will use the urlValue set here:
    window._sembiAIDesignGuidanceModalUrl = this.urlValue;
  }

  openModal(e) {
    e.preventDefault();
    this.constructor.openModal()
  }

  static openModal() {
    HW.dialogs.ajax(window._sembiAIDesignGuidanceModalUrl, undefined, undefined, {
      width: Math.min(window.innerWidth - 200, 1238),
      height: window.innerHeight - 200,
      center: true,
      transitions: false,
      disableTitle: false,
      defaultFooter: false,
      footer: '<button class="btn" onclick="Modalbox.hide();">Done</button>',
      frameClasses: 'wide-modal ai-guidance-modal hide-header',
      containerClass: 'no-container-padding'
    })
  }
}

window.sembiAIOpenDesignGuidanceModal = DesignGuidanceModalController.openModal;