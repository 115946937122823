import HexController from 'controllers/hex_controller'

export default class extends HexController {
  static values = ['count']

  connect() {
    window.parametersCountController = this;
  }

  updateCount(count) {
    if (count !== this.countValue) {
      this.countValue = count;
      this.sendParametersCountChanged();
    }
  }

  sendParametersCountChanged() {
    document.dispatchEvent(new CustomEvent('parameters-count-changed', {
      detail: {
        parametersCount: this.countValue
      }
    }));
  }
}