import HexController from 'controllers/hex_controller';

export default class DesignModalFooterButtonController extends HexController {
  connect() {
    document.addEventListener('design-guidance-changed', this.designGuidanceChanged.bind(this));
  }

  disconnect() {
    document.removeEventListener('design-guidance-changed', this.designGuidanceChanged.bind(this));
  }

  designGuidanceChanged(event) {
    const { canShow } = event.detail;
    this.element.disabled = !canShow;
  }
}