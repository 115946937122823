import HexController from 'controllers/hex_controller'

export default class extends HexController {
  static values = { title: String, options: Object, position: String, offsetX: Number, offsetY: Number };
  _tip = null;

  connect() {
    const options = {
      behavior: 'hide',
      maxWidth: 350,
      stem: false,
      position: {
        target: 'bottommiddle'
      },
      size: 'large',
      skin: 'dark',
      offset: {
        x: this.offsetXValue || 0,
        y: this.offsetYValue || 0
      }
    }

    const position = { position: { target: this.positionValue || options.position.target } }
    const mergedOptions = { ...options, ...position, ...this.optionsValue }

    this._tip = Tipped.create(this.element, this.titleValue, mergedOptions);
  }

  disconnect() {
    // Silently ignore errors
    try { this._tip?.remove(); } catch {}
  }
}
