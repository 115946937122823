import HexController from 'controllers/hex_controller';

export default class ReplaceWithSpinnerController extends HexController{
  connect() {
    this.element.addEventListener('click', (event) => this.onclick(event));
  }

  onclick(_event) {
    if (this.element.tagName.toLowerCase() === 'i') {
      this.replaceWithSpinner(this.element);
    } else {
      this.replaceWithSpinner(this.element.querySelector('i.material-icons'));
    }
  }

  replaceWithSpinner(el) {
    if (el) {
      el.className = 'material-icons autorenew active';
      this.constructor.disableAllAIButtons();
    }
  }

  static disableAllAIButtons() {
    const aiBtns = document.querySelectorAll('.ai-btn');
    aiBtns.forEach((btn) => {
      btn.style.pointerEvents = 'none';
      btn.style.opacity = '0.5';
    });
  }
};

window.sembiAiReplaceWithSpinnerController = ReplaceWithSpinnerController;
