import HexController from 'controllers/hex_controller'
import { throttle } from 'underscore'

export default class extends HexController {
  static targets = [
    'filesExpirationContainer',
    'filesExpirationInput',
    'keepFilesSelect'
  ]

  static outlets = ['sembi-ai--system-files-form'];

  static values = {
    url: String
  };

  connect() {
    this._updateExpiration = throttle(this.updateExpiration, 2000, { trailing: true, leading: true });
    this.toggelFileExpirationContainer();
    if (this.hasKeepFilesSelectTarget) this.keepFilesSelectTarget.addEventListener('change', _ => this.toggelFileExpirationContainer());
    this.filesExpirationInputTarget.addEventListener('input', _ => this.expirationDidChange());
  }

  toggelFileExpirationContainer() {
    if (this.hasKeepFilesSelectTarget && !this.keepFilesSelectTarget.checked) {
      this.filesExpirationContainerTarget.classList.add('hidden');
    } else {
      this.filesExpirationContainerTarget.classList.remove('hidden');
    }
    this.expirationDidChange();
  }

  getExpiration() {
    return this.hasKeepFilesSelectTarget && this.keepFilesSelectTarget.checked ? this.filesExpirationInputTarget.value : 0;
  }

  expirationDidChange() {
    const expiration = this.getExpiration();
    this.sembiAiSystemFilesFormOutlet.setFilesExpiration(expiration);
    this._updateExpiration();
  }

  updateExpiration() {
    const expiration = this.getExpiration();
    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        files_expiration: expiration
      })
    }).catch(error => {
      console.error(error);
    });
  }
}
