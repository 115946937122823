import HexController from 'controllers/hex_controller'

export default class extends HexController {
  static targets = ['submitButton', 'coverageGoalTextarea', 'minLengthLabel', 'redDot', 'useTestingGoalButton', 'clearCoverageGoalButton']

  static values = {
    testingGoal: String,
    minLength: Number
  }

  connect() {
    if (this.hasCoverageGoalTextareaTarget) {
      this.coverageGoalTextareaTarget.addEventListener('input', () => {
        this.validateCoverageGoal();
        if (this.coverageGoalTextareaTarget.value.trim().length === 0) {
          this.useTestingGoalButtonTarget.classList.remove('hidden');
        }
      });
      this.validateCoverageGoal();
    }

    if (this.hasClearCoverageGoalButtonTarget) {
      this.clearCoverageGoalButtonTarget.addEventListener('click', e => {
        e.preventDefault();
        this.coverageGoalTextareaTarget.value = '';
        this.validateCoverageGoal();
        this.useTestingGoalButtonTarget.classList.remove('hidden');
      });
    }
  }

  submit() {
    Modalbox.hide();
    window.sembiAILoadingIndicator.activate();
  }

  useTestingGoal() {
    this.useTestingGoalButtonTarget.classList.add('hidden');
    this.coverageGoalTextareaTarget.value = `${this.coverageGoalTextareaTarget.value || ''} ${this.testingGoalValue}`.trim();
    this.validateCoverageGoal();
    this.coverageGoalTextareaTarget.focus();
    // Move the cursor to the end of the text
    this.coverageGoalTextareaTarget.selectionStart = this.coverageGoalTextareaTarget.selectionEnd = this.coverageGoalTextareaTarget.value.length;
  }

  validateCoverageGoal() {
    if (this.coverageGoalTextareaTarget.value.split(/\s/).filter(Boolean).length < this.minLengthValue) {
      this.submitButtonTarget.disabled = true;
      this.redDotTarget.classList.remove('hidden');
      this.minLengthLabelTarget.classList.remove('hidden');
      this.submitButtonTarget.dataset.controller = 'tooltip';
      this.submitButtonTarget.dataset.tooltipTitleValue = `Please provide a testing goal of at least ${this.minLengthValue} words.`;
      this.clearCoverageGoalButtonTarget.classList.add('hidden');
    } else {
      this.submitButtonTarget.disabled = false;
      this.redDotTarget.classList.add('hidden');
      this.minLengthLabelTarget.classList.add('hidden');
      delete this.submitButtonTarget.dataset.controller;
      delete this.submitButtonTarget.dataset.tooltipTitleValue;
      this.clearCoverageGoalButtonTarget.classList.remove('hidden');
    }
  }
}
