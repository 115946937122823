import HexController from 'controllers/hex_controller';

export default class extends HexController {
  _locking = false;

  connect() {
    window.navigationLockController = this;
  }

  disconnect() {
    this.unlock();
    window.navigationLockController = null;
  }

  navCheck(event) {
    if (this._locking) {
      event.preventDefault();
      event.stopPropagation();
      this.showWarningDialog(event.detail.url);
    }
  }

  lock() {
    this._locking = true;
    this.setOnBeforeUnload();
    document.querySelectorAll('a[data-turbo="false"]').forEach(function(element) {
      element.addEventListener('click', event => {
        window.navigationLockController?.navCheck(event);
      });
    });
    document.addEventListener("turbo:before-visit", event => {
      this.navCheck(event);
    });
  }

  unlock() {
    if (this._locking) {
      this._locking = false;
      this.unsetOnBeforeUnload();
      document.querySelectorAll('a').forEach(function(element) {
        element.removeEventListener('click', event => this.navCheck(event));
      });
      document.removeEventListener("turbo:before-visit", event => this.navCheck(event));
    }
  }

  setOnBeforeUnload() {
    window.onbeforeunload = function() {
      return '';
    }
  }

  unsetOnBeforeUnload() {
    window.onbeforeunload = null;
  }

  showWarningDialog(toUrl) {
    const title = this.t('Lose Unsaved Changes Warning');
    const footer = `<button class='btn' onclick="Modalbox.hide()">${this.t('Stay Here')}</button>` +
                   `<button class='btn btn-danger' onclick="window.navigationLockController.unlock(); window.location.href=\'${toUrl}\'">${this.t('Yes, lose changes')}</button>`;
    const options = {
      title: title,
      width: 700,
      center: true,
      transitions: false,
      defaultFooter: true,
      footer: footer,
      // containerClass: 'no-container-padding'
    };
    const body = `<div>${this.t('Leaving this page will cause your unsaved changes to be lost. Are you sure you want to continue?')}</div>` +
                 `<div><strong>${this.t('Choosing "Yes" will cause all unsaved changes to be lost!')}</strong></div>`;
    Modalbox.show(body, options, footer);
  }
}
