import HexController from 'controllers/hex_controller'

export default class NewPillboxController extends HexController {
  connect() {
    document.addEventListener('parameters-count-changed', (event) => {
      if (event.detail.parametersCount > 0) {
        this.element.classList.remove('red-badge')
      } else {
        this.element.classList.add('red-badge')
      }
    })
  }
}