import HexController from 'controllers/hex_controller'

export default class SuggestParamValuesButtonController extends HexController {
  static values = {
    planId: Number,
    parameterId: Number,
    expandUrl: String,
    contractUrl: String,
    action: String,
    canShow: Boolean
  };

  connect() {
    // Listen for the design-guidance-changed event and change the canShowValue with the value of the event
    document.addEventListener('design-guidance-changed', (event) => {
      this.canShowValue = event.detail.canShow;
    });
  }

  async click(_event) {
    if (!this.canShowValue) {
      HW.dialogs.showDesignGuidanceAlertDialog();
      return;
    }
    try {
      if (this.actionValue === 'parameter-contract') {
        window.sembiAILoadingIndicator.activate();
        const response = await fetch(this.contractUrlValue)
        if (response.status < 200 || response.status >= 300) {
          window.sembiAILoadingIndicator.deactivate();
        }
      } else {
        HW.utils.handleParameterHeadingClick(this.expandUrlValue);
      }
    } catch (error) {
      window.sembiAILoadingIndicator.deactivate();
    }
  }
}
