import HexController from 'controllers/hex_controller'

export default class extends HexController {
  static values = {
    index: String,
  }

  connect() {
    // Subscribe to turbo form submition succeed event
    this.element.addEventListener('turbo:submit-end', () => this.submitEnded());
  }

  submitEnded() {
    // Remove all the hidden fields with class 'suggested-parameter-#{index}'
    this.element.querySelectorAll(`input[type="hidden"].suggested-parameter-${this.indexValue}`).forEach((field) => {
      field.remove();
    });
  }
}
