import HexController from 'controllers/hex_controller'
import { v4 as uuidv4 } from 'uuid';
import { uniq } from 'lodash';

export default class extends HexController {
  static targets = ['values', 'name', 'loadingIndicator', 'suggestions', 'generateButton', 'response', 'generateButtonText', 'generateButtonMoreText'];
  static values = {
    url: String,
    canShow: Boolean,
    parameterName: String,
    autoStart: Boolean
  };
  _loadingTimer = null;
  _response = null;
  _lastRequestUuid = null;
  _previousValues = null;

  connect() {
    // Only if the generate button is present
    if (this.hasGenerateButtonTarget) {
      // Unfortunately, the nameTarget doesn't trigger input events on change, so we need to listen to the combobox input instead
      document.getElementById(`parameter_name-${this.parameterNameValue}`).addEventListener('input', e => this._maybeShowGenerateButton(e?.target?.value));
      this.element.addEventListener('submit', () => {
        window.navigationLockController?.setOnBeforeUnload();
      });
      this.valuesTarget.addEventListener('input', _ => this._updateGenerateButtonLabel());
      this._updateGenerateButtonLabel();
      setTimeout(() => {
        if (this.autoStartValue) {
          this.generate();
        }
      }, 100);
    }
    // Listen for the design-guidance-changed event and change the canShowValue with the value of the event
    document.addEventListener('design-guidance-changed', (event) => {
      this.canShowValue = event.detail.canShow;
    });
  }

  generate() {
    if (!this.canShowValue) {
      Modalbox.hide();
      setTimeout(() => {
        HW.dialogs.showDesignGuidanceAlertDialog();
      }, 100);
      return;
    }
    this.suggestionsTarget.classList.add('hidden');
    this.suggestionsTarget.classList.remove('error');
    const name = this.nameTarget.value;
    const values = this.valuesTarget.value;
    this.startLoading();
    this._lastRequestUuid = uuidv4();
    this._response = null;
    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        name,
        values,
        request_uuid: this._lastRequestUuid
      })
    }).catch(() => {
      this.stopLoading();
    });
    // Stop loading after 90 seconds
    this._loadingTimer = setTimeout(() => {
      this.stopLoading();
      this._resetSuggestionsArea();
    }, 90000);
  }

  _maybeShowGenerateButton(value) {
    // Do not show/hide the button if we are waiting for a response or showing suggestions
    if (!this._loadingTimer && !this._response) {
      if (value?.length >= 3) {
        this.generateButtonTarget.classList.remove('hidden');
      } else {
        this.generateButtonTarget.classList.add('hidden');
      }
      this._updateGenerateButtonLabel();
    }
  }

  _resetSuggestionsArea() {
    this.valuesTarget.classList.remove('highlight-border');
    this.suggestionsTarget.innerHTML = '';
    this.suggestionsTarget.classList.add('hidden');
    this._previousValues = null;
    this._response = null;
    this._lastRequestUuid = null;
    this._loadingTimer = null;
    this._maybeShowGenerateButton(this.nameTarget.value);
  }

  _addParametersToValues(paramsList) {
    const currentValues = this.valuesTarget.value.split('\n').map(v => v.trim());
    const newValues = uniq([...currentValues, ...paramsList]).filter(Boolean);
    this.valuesTarget.value = newValues.join('\n');
  }

  _acceptIcon() {
    const acceptIcon = document.createElement('i');
    acceptIcon.classList.add('material-icons', 'check');
    return acceptIcon;
  }

  _rejectIcon() {
    const rejectIcon = document.createElement('i');
    rejectIcon.classList.add('material-icons', 'close');
    return rejectIcon;
  }

  _newParameterRow(parameter) {
    const li = document.createElement('li');
    const paramSpan = document.createElement('span');
    paramSpan.textContent = parameter;
    li.appendChild(paramSpan);

    const removeParameterFromList = () => {
      li.parentElement.removeChild(li);
      const nextValues = this._response.values.filter(v => v !== parameter)
      this._response = {
        ...this._response,
        values: nextValues
      };
      if (nextValues.length === 0) {
        this._resetSuggestionsArea();
      }
    }

    const acceptButton = document.createElement('button');
    acceptButton.classList.add('ai-btn', 'ai-btn-accept', 'ai-btn-small');
    acceptButton.onclick = () => {
      this._addParametersToValues([parameter]);
      removeParameterFromList();
    };
    acceptButton.appendChild(this._acceptIcon());
    li.appendChild(acceptButton);

    const rejectButton = document.createElement('button');
    rejectButton.classList.add('ai-btn', 'ai-btn-reject', 'ai-btn-small');
    rejectButton.onclick = () => {
      removeParameterFromList();
    };
    rejectButton.appendChild(this._rejectIcon());
    li.appendChild(rejectButton);

    return li;
  }

  _newParametersList(paramsList) {
    const responseDiv = document.createElement('div');
    responseDiv.classList.add('response-header');

    const allVariationsSpan = document.createElement('span');
    allVariationsSpan.classList.add('response-header-label');
    allVariationsSpan.textContent = i18n.translate('All Variations').fetch();
    responseDiv.appendChild(allVariationsSpan);

    const acceptAllButton = document.createElement('button');
    acceptAllButton.classList.add('ai-btn', 'ai-btn-accept');
    acceptAllButton.appendChild(this._acceptIcon());
    const acceptAllText = document.createElement('span');
    acceptAllText.textContent = i18n.translate('Accept all').fetch();
    acceptAllButton.appendChild(acceptAllText);
    acceptAllButton.onclick = () => {
      this._addParametersToValues(this._response.values);
      this._resetSuggestionsArea();
    };
    responseDiv.appendChild(acceptAllButton);

    const rejectAllButton = document.createElement('button');
    rejectAllButton.classList.add('ai-btn', 'ai-btn-reject');
    rejectAllButton.appendChild(this._rejectIcon());
    const rejectAllText = document.createElement('span');
    rejectAllText.textContent = i18n.translate('Reject all').fetch();
    rejectAllButton.appendChild(rejectAllText);
    rejectAllButton.onclick = () => {
      this._resetSuggestionsArea();
    };
    responseDiv.appendChild(rejectAllButton);

    const responseList = document.createElement('ul');
    responseList.classList.add('response-list');
    paramsList.forEach(param => {
      responseList.appendChild(this._newParameterRow(param));
    });

    this.suggestionsTarget.appendChild(responseDiv);
    this.suggestionsTarget.appendChild(responseList);
  }

  _updateGenerateButtonLabel() {
    if (this.valuesTarget.value.trim().length > 0) {
      this.generateButtonTextTarget.classList.add('hidden');
      this.generateButtonMoreTextTarget.classList.remove('hidden');
    } else {
      this.generateButtonTextTarget.classList.remove('hidden');
      this.generateButtonMoreTextTarget.classList.add('hidden');
    }
  }

  _updateSuggestions() {
    const existingParams = this.valuesTarget.value.split('\n').map(v => v.trim()).filter(Boolean);
    const newParams = this._response.values.filter((v) => {
      return Boolean(v) && !existingParams.includes(v);
    });
    this.valuesTarget.classList.add('highlight-border');
    this.suggestionsTarget.classList.remove('hidden');
    this._newParametersList(newParams);
    this.generateButtonTarget.classList.add('hidden');
    this._updateGenerateButtonLabel();
  }

  responseTargetConnected() {
    try {
      this._response = JSON.parse(this.responseTarget.textContent);
    } catch (e) {
      this._response = null;
    }
    clearTimeout(this._loadingTimer);
    this._loadingTimer = null;
    this.stopLoading();

    this.valuesTarget.classList.remove('highlight-border');
    if (this._response?.request_uuid?.length > 0) {
      // Render the responses only if this was the last request, if the user managed to start a new request in the meantime, ignore it
      if (this._lastRequestUuid === this._response.request_uuid) {
        if (this._response.message?.length > 0) {
          this.suggestionsTarget.innerHTML = this._response.message;
        }
        if (this._response.status === 'error') {
          // Show an error message
          this.suggestionsTarget.classList.add('error');
          this.suggestionsTarget.classList.remove('hidden');
          this.generateButtonTarget.classList.remove('hidden');
        } else {
          window.navigationLockController?.setOnBeforeUnload();
          // Apply the suggestions received
          this._previousValues = this.valuesTarget.value;
          this._updateSuggestions();
          // Focus the values textarea and place the cursor at the end
          this.valuesTarget.focus();
          this.valuesTarget.selectionStart = this.valuesTarget.selectionEnd = this.valuesTarget.value.length;
        }
      }
    } else {
      this._resetSuggestionsArea();
    }
  }

  startLoading() {
    this.loadingIndicatorTarget.classList.remove('hidden');
    this.generateButtonTarget.classList.add('hidden');
    if (this.hasResponseTarget) {
      this.responseTarget?.remove();
    }
    this.suggestionsTarget.innerHTML = '';
    this.suggestionsTarget.classList.add('hidden');
  }

  stopLoading() {
    this.loadingIndicatorTarget.classList.add('hidden');
  }
}
